import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import AboutData from '../../data/main/aboutus.json';

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img src={process.env.PUBLIC_URL + AboutData.body.image} alt="travel" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                  <span className="subtitle">{AboutData.body.subtitle}</span>
                  <h2 className="title">{AboutData.body.title}</h2>
                  {
                    AboutData.body.para && 
                    Array.isArray(AboutData.body.para) &&
                    AboutData.body.para.map((data, index) => (
                      <p key={index} dangerouslySetInnerHTML={{__html: data}}></p>
                    )
                  )}
                  {AboutData.body.submit && ( 
                    <Link to={process.env.PUBLIC_URL + AboutData.body.submitUrl} className="axil-button btn-large">
                      <span className="button-text">{AboutData.body.submit}</span>
                    </Link>
                  )}
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({isVisible}) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="86" duration={1} /> : null}
                        </span>
                      )}  
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Collectively Years of experience</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({isVisible}) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="50" duration={1} /> : null}
                        </span>
                      )}  
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Projects delivered so far</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutFour;