import React from 'react';


const SectionTitle = ({subtitle, title, textAlignment, textColor}) => {
  return (
    <div className={`section-heading ${textAlignment} ${textColor}`}>
      <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}}></div>
      <h2 className="title" dangerouslySetInnerHTML={{__html: title}}></h2>
    </div>
  )
}

export default SectionTitle;