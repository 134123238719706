import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import ContactData from "../../data/contact/contactData.json";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">{ContactData.form.successMsg}</Alert>
  )
}

const FormTwo = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_bcoi2bt', 'template_crn5io4', form.current, 'UN89o9GmbHaDH-lOQ')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        form.current.reset();
        showresult(true);
      };

        setTimeout(() => {
          showresult(false);
        }, 5000);


    return (
      <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
          <label>{ContactData.form.name}</label>
          <input type="text" className="form-control" name="contact-name" required />
        </div>
        <div className="form-group">
          <label>{ContactData.form.email}</label>
          <input type="email" className="form-control" name="contact-email" required />
        </div>
        <div className="form-group">
          <label>{ContactData.form.phone}</label>
          <input type="tel" className="form-control" name="contact-phone" required />
        </div>
        <div className="form-group mb--40">
          <label>{ContactData.form.message}</label>
          <textarea className="form-control" name="contact-message" rows="4"></textarea>
        </div>
        <div className="form-group">
          <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">{ContactData.form.submit}</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>
    </form>
    )
}

export default FormTwo;