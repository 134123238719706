import React from 'react';
import FooterOne from '../common/footer/FooterGV';
import HeaderOne from '../common/header/HeaderGV';
import SEO from '../common/SEO';
import BcrumbBanner from '../elements/breadcrumb/BcrumbBannerService';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
// import AboutFive from '../component/about/AboutFive';
import AboutData from '../data/main/aboutus.json';

const AboutUs = () => {
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBanner 
          title={AboutData.title}
          page={AboutData.page}
          paragraph ={AboutData.para}
          styleClass="thumbnail-4"
          mainThumb={AboutData.hero || "/images/banner/banner-thumb-4.png"}
        />
        <AboutFour />
        <AboutThree />
        {/* <AboutFive /> */}
        <ProcessOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  )
}

export default AboutUs;