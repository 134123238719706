import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import AboutData from '../../data/main/aboutus.json';



const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle 
            subtitle={AboutData.ourValues.subtitle}
            title={AboutData.ourValues.title}
            description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
            textAlignment="heading-left heading-light-left mb--100"
            textColor=""
        />
        <div className="row">
            {
            AboutData.ourValues &&
            AboutData.ourValues.para &&
            Array.isArray(AboutData.ourValues.para) &&
            AboutData.ourValues.para.map((data, index) => (
                <div className="col-lg-4" key={index}>
                    <div className="about-quality">
                        <h3 className="sl-number">{data.id}</h3>
                        <h5 className="title">{data.title}</h5>
                        {data.para && Array.isArray(data.para) && data.para.map((data, index) => (
                            <p key={index}>{data}</p>
                        ))}
                        {data.para && !Array.isArray(data.para) && <p>{data.para}</p>}
                    </div>
                </div>
            ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
      </ul>
    </div>
  )
}

export default AboutThree;