import React from 'react';
import FooterOne from '../common/footer/FooterGV';
import HeaderOne from '../common/header/HeaderGV';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne 
          title="Privacy Policy"
          page="Privacy Policy"
        />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">This Privacy policy was published on January 25, 2024.</h5>
                  </div>
                  <h4>Introduction</h4>
                  <p>Grapevine respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our website <a href="http://wwww.grapevinelab.com" _target="blank">https://www.grapevinelab.com</a></p>
                 
                  <h4>Information We Collect</h4>
                  <p className="mb--20">We may collect the following types of information from you:</p>
                  <ul>
                    <li>
                      <b>Personal Information:</b>
                      This includes information that can be used to identify you, such as your name, 
                      email address, postal address, phone number, and demographic information.</li>
                    <li>
                      <b>Usage Information:</b>
                      This includes information about how you use our website, such as the pages you visit, the links you click, and the searches you make.
                    </li>
                    <li>
                      <b>Device Information:</b>
                      This includes information about the device you use to access our website, such as the type of device, browser, operating system, and IP address.</li>
                  </ul>

                  <h4>How We Use Your Information</h4>
                  <p className="mb--20">We use your information to:</p>
                  <ul>
                      <li>Provide and improve our website and services.</li>
                      <li>Create and manage your account.</li>
                      <li>Personalize your experience on our website.</li>
                      <li>Send you important information about our website and services.</li>
                      <li>Respond to your inquiries and requests.</li>
                      <li>Fulfill legal obligations.</li>
                  </ul>

                  <h4>How We Share Your Information</h4>
                  <p className="mb--20">We may share your information with:</p>
                  <ul>
                      <li><b>Service providers:</b> 
                        We may share your information with third-party service providers who help us operate and improve our website, 
                        such as web hosting providers, data analytics providers, and customer service providers.</li>
                      <li><b>Law enforcement:</b> 
                        We may disclose your information to law enforcement agencies if we are required to do so by law, 
                        or if we believe it is necessary to protect the safety of others.</li>
                      <li><b>Other third parties:</b>
                        We may share your information with other third parties with your consent,
                        or as otherwise permitted by law.</li>
                  </ul>

                  <h4>Your Choices</h4>
                  <p className="mb--20">You have the following choices regarding your information:</p>
                  <ul>
                      <li>You can access and update your information by logging into your account.</li>
                      <li>You can opt out of receiving marketing communications from us by clicking the "unsubscribe" link in our emails.</li>
                      <li>You can request that we delete your information by contacting us at <a href="mailto:contact@grapevinelab.com">contact@grapevinelab.com</a></li>
                  </ul>

                  <h4>Data Retention</h4>
                  <p>We will retain your information for as long as it is necessary to provide you with our services, to comply with legal obligations, or to resolve disputes.</p>
                  
                  <h4>Security</h4>
                  <p>We have implemented appropriate technical and organizational measures to protect your information 
                    from unauthorized access, disclosure, alteration, or destruction. However, 
                    no method of transmission over the internet or method of electronic storage is 100% secure. 
                    Therefore, we cannot guarantee the absolute security of your information.</p>

                  <h4>Children's Privacy</h4>
                  <p>Our website is not intended for children under the age of 13. 
                    We do not knowingly collect personal information from children under the age of 13. 
                    If you are a parent or guardian and you are aware that your child has provided us with personal information, 
                    please contact us.</p>

                  <h4>Contact Us</h4>
                  <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@grapevinelab.com">contact@grapevinelab.com</a>.</p>
                  <p><b>Please note:</b> This is just a sample privacy policy and may not be comprehensive or complete for all websites.
                    You should consult with an attorney to ensure that your privacy policy complies with all applicable laws and regulations.</p>
                  <p>I hope this helps! Let me know if you have any other questions.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
      <FooterOne parentClass="" />
      </main>
    </>
  )
}

export default PrivacyPolicy;