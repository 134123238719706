import React from 'react';
import FooterOne from '../common/footer/FooterGV';
import HeaderOne from '../common/header/HeaderGV';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerService';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';


const CaseStudy = () => {
    return (
      <>
        <SEO title="Case Study" />
        <ColorSwitcher />
          <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Case Study"
            paragraph ="Grapevine offers a variety of customisable and scalable IT solutions to resolve business challenges by customers across multiple industries."
            styleClass="thumbnail-3"
            mainThumb="/images/banner/banner-thumb-5-color.png"
            />
            <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
              <div className="container">
                <CaseStudyProp />
              </div>
            </div>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
          </main>
      </>
    )
}

export default CaseStudy;