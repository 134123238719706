import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaPhone, FaFax, FaWhatsapp } from "react-icons/fa";
// import { FaFacebookF, FaLinkedinIn, FaBehance } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>26-6, Menara Mutiara Sentral,</p>
                                    <p>2, Jalan Desa Aman 1,</p>
                                    <p>Cheras Business Centre, 56100 Cheras,</p>
                                    <p>Federal Territory of Kuala Lumpur,</p>
                                    <p>Malaysia</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:60391349498" className="tel"><FaPhone /> (603) 9134-9498</a>
                                    <a href="tel:60149889258" className="tel"><FaPhone /> (6014) 988-9258</a>
                                    <a href="tel:60327268915" className="tel"><FaFax /> (603) 2726-8915</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://wa.me/60149889258" target='blank'><FaWhatsapp /></a>
                                        </li>
{/* 
                                        <li>
                                            <a href="https://twitter.com/"><FaXTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/"><FaLinkedinIn /></a> 
                                        </li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
