import React from 'react';

const Data = [
    {
        image: "/images/brand/amz.png"
    },
    {
        image: "/images/brand/houzmoni.png"
    },
    {
        image: "/images/brand/scrumhr.png"
    },
    {
        image: "/images/brand/dapor.png"
    },
    {
        image: "/images/brand/catthis.png"
    },
    {
        image: "/images/brand/dtechmy.png"
    },
    {
        image: "/images/brand/gnf.png"
    },
    {
        image: "/images/brand/imu.png"
    },
    {
        image: "/images/brand/erajayaoutdoor.png"
    },
    {
        image: "/images/brand/mt.png"
    },
    {
        image: "/images/brand/jomrun.png"
    },
    {
        image: "/images/brand/bbit.png"
    },
    {
        image: "/images/brand/ido.png"
    },
    {
        image: "/images/brand/minshuku.png"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" className="logo" width={230} height={133} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;