import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaTiktok } from "react-icons/fa";
// import { FaEnvelopeOpen } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import FooterData from "../../data/main/footer.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const Footer = ({parentClass}) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              {FooterData.facebook && <li><Link to={FooterData.facebook}><FaFacebookF /></Link></li>}
              {FooterData.twitter && <li><Link to="https://twitter.com/"><FaXTwitter /></Link></li>}
              {FooterData.pinterest && <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>}
              {FooterData.linkedin && <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>}
              {FooterData.instagram && <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>}
              {FooterData.vimeo && <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>}
              {FooterData.dribbble && <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>}
              {FooterData.behance && <li><Link to="https://www.behance.net/"><FaBehance /></Link></li>}
              {FooterData.tiktok && <li><Link to="https://www.tiktok.com/en/"><FaTiktok /></Link></li>}
            </ul>
          </div>
        </div>
        <div className="footer-main">
            <div className="row">
                <div className="col-xl-6 col-lg-5">
                    <div className="footer-widget border-end">
                        <div className="footer-newsletter">
                            <h2 className="title">{FooterData.getintouch.title}</h2>
                            <p>{FooterData.getintouch.description}</p>
                            {/* <form>
                                <div className="input-group">
                                    <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                    <input type="email" className="form-control" placeholder="Email address" />
                                    <button className="subscribe-btn" type="submit">Subscribe</button>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-7">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="footer-widget">
                                <h6 className="widget-title">Services</h6>
                                <div className="footer-menu-link">
                                    <ul className="list-unstyled">
                                        {getServiceData.slice(0, 6).map((data, index) => (
                                            <li key={index}>
                                                <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            {FooterData.otherlinks1.enable && 
                            <div className="footer-widget">
                                <h6 className="widget-title">{FooterData.otherlinks1.title}</h6>
                                <div className="footer-menu-link">
                                    <ul className="list-unstyled">
                                        {FooterData.otherlinks1.links.map((data, index) => (
                                            <li key={index}><Link to={process.env.PUBLIC_URL + data.link}>{data.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                        <div className="col-sm-3">
                            {FooterData.otherlinks2.enable && 
                            <div className="footer-widget">
                                <h6 className="widget-title">{FooterData.otherlinks2.title}</h6>
                                <div className="footer-menu-link">
                                    <ul className="list-unstyled">
                                        {FooterData.otherlinks2.links.map((data, index) => (
                                            <li key={index}><Link to={process.env.PUBLIC_URL + data.link}>{data.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom"> 
            <div className="row">
                <div className="col-md-6">
                    <div className="footer-copyright">
                        <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://www.grapevinelab.com/">Grapevine PLT (LLP0019090-LGN)</a>.</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="footer-bottom-link">
                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                            {/* <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;