import React from 'react';
import Tilty from 'react-tilty';
import {useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterGV';
import HeaderOne from '../common/header/HeaderGV';
import BcrumbBanner from '../elements/breadcrumb/BcrumbBannerService';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
// import AboutTwo from '../component/about/AboutTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
// import SectionProject from '../component/project/SectionProject';

const allServiceData = ServiceData;

const ServiceDetails = () => {

  const params = useParams();
  const serviceSlug = params.slug;

  const getServiceData = allServiceData.filter(data => (data.slug || slugify(data.title)) === serviceSlug);
  const detailsService = getServiceData[0];

  return (
    <>
      <SEO title="Service Details" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBanner 
          title={detailsService.title}
          page={detailsService.title}
          description ={detailsService.description}
          paragraph ={detailsService.para || ""}
          styleClass=""
          mainThumb={detailsService.hero || "/images/banner/banner-thumb-4.png"}
        />
        {/* <AboutTwo /> */}
        <div className="section section-padding bg-color-light pb--70">
          {detailsService.process && (
          <SectionTitle 
              subtitle={detailsService.process.sectionSub}
              title={detailsService.process.sectionTitle}
              textAlignment="text-left mb--50"
              textColor="mb--90"
          />)}
          <div className="container">
            { detailsService && 
            detailsService.process && 
            detailsService.process.para && 
            Array.isArray(detailsService.process.para) &&
            detailsService.process.para.map((data, index) => (
              <p key={index} dangerouslySetInnerHTML={{__html: data}}></p>
            ))}
            {detailsService.process && detailsService.process.steps.map((data, index) => (
              <div key={index} className={`process-work ${(index % 2  === 1) ? "content-reverse" : ""}`}>
                <Tilty perspective={2000}>
                  <div className="thumbnail">
                    <img src={process.env.PUBLIC_URL + data.thumb} alt="Thumbnail" />
                  </div>
                </Tilty>
              <div className="content">
                <span className="subtitle">{data.subtitle}</span>
                <h3 className="title">{data.title}</h3>
                <p>{data.paragraph}</p>
              </div>
            </div>
            ))}
          </div>
          <ul className="shape-group-17 list-unstyled">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
            <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
            <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
          </ul>
        </div>
        {/* <SectionProject /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  )
}

export default ServiceDetails;