import React from 'react';
import FooterOne from '../common/footer/FooterGV';
import HeaderOne from '../common/header/HeaderGV';
import SEO from '../common/SEO';
// import AboutOne from '../component/about/AboutOne';
import BannerHero from '../component/banner/BannerHero';
// import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
// import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
// import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
// import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
// import PricingGV from '../component/pricing/PricingGV';

const GVLanding = () => {
  return (
    <>
      <SEO title="Landing"/>
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerHero />
        <div className="section section-padding-2 bg-color-dark">
          <div className="container">
            <SectionTitle 
              subtitle="What We Can Do For You"
              title="Services we can help you with"
              description="Here’s some of the services that Grapevine offers to SMEs and startups:"
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className='row'>
              <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
          </ul>
        </div>
        {/* <AboutOne />
        <ProjectOne /> */}
        {/* <CounterUpOne /> */}
        {/* <TestimonialOne /> */}
        {/* <PricingGV /> */}
        <BrandOne />
        {/* <BlogOne /> */}
        <CtaLayoutOne /> 
        <FooterOne parentClass="" />
      </main>
    </>
  )
}

export default GVLanding;

