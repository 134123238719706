import React from 'react';
import Tilty from 'react-tilty';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const BcrumbBanner = ({page, title, description, paragraph, styleClass, mainThumb, submit}) => {
  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="breadcrumb">
          <ul className="list-unstyled">
            <li>
                <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
            </li>
            <li className="active" dangerouslySetInnerHTML={{__html: page}}></li>
          </ul>
        </div>

        <div className="row align-items-end align-items-xl-start">
          <div className="col-lg-6">
            <div className="banner-content">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title" dangerouslySetInnerHTML={{__html: title}}></h1>
                { paragraph && 
                  Array.isArray(paragraph) &&
                  paragraph.map((data, index) => (
                  <span key={index} dangerouslySetInnerHTML={{__html: data}} className="subtitle"></span>
                ))}
                { paragraph &&
                  !Array.isArray(paragraph) &&
                  <span dangerouslySetInnerHTML={{__html: paragraph}} className="subtitle"></span>
                }
                { description && 
                  Array.isArray(description) &&
                  description.map((data, index) => (
                  <p key={index} dangerouslySetInnerHTML={{__html: data}}></p>
                ))}
                { description &&
                  !Array.isArray(description) &&
                  <p dangerouslySetInnerHTML={{__html: description}}></p>
                }
                {submit && <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large">Get Started</Link>}
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-6">
            <div className={`banner-thumbnail ${styleClass}`}>
              <Tilty perspective={2000} reset={false}>
                <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
              </Tilty>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
        </li>
        <li className="shape shape-3">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
        </li>
        <li className="shape shape-4">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
        </li>
        <li className="shape shape-5">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
        </li>
        <li className="shape shape-6">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
        </li>
        <li className="shape shape-7">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
        </li>
      </ul>
    </div>
  )
}

export default BcrumbBanner;